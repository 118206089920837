import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoginButton } from '../LoginButton/LoginButton';
import { useTranslation, getI18n } from 'react-i18next';
import logo from '../../images/logo-mobile.svg';
import { quotes } from './quotes';
var quote = quotes[Math.floor(Math.random() * quotes.length)];
import './MainScreen.scss';
var MainScreen = function () {
    var t = useTranslation().t;
    var lang = getI18n().language;
    return (_jsxs("div", __assign({ className: 'mainScreen row' }, { children: [_jsx("img", { src: logo, className: 'mainScreen__logo--mobile' }), _jsxs("div", __assign({ className: 'col mainScreen__col' }, { children: [_jsx("img", { src: logo, className: 'mainScreen__logo' }), _jsxs("h1", __assign({ className: 'mainScreen__title' }, { children: [t('Welcome to'), " Saedi"] })), _jsx("h2", __assign({ className: 'mainScreen__descr' }, { children: t('The best place') })), _jsx(LoginButton, { param: 'Google' }), _jsx(LoginButton, { param: 'Facebook' }), _jsxs("p", __assign({ className: 'mainScreen__privacy' }, { children: [t('This site is protected'), lang == 'ru' ? ", ".concat(t('apply'), " ") : ' ', _jsx("a", __assign({ href: '#', target: '_blank' }, { children: t('Privacy Policy') })), ' ' + t('and') + ' ', _jsx("a", __assign({ href: '#', target: '_blank' }, { children: t('Terms of Service') })), lang !== 'ru' ? " ".concat(t('apply')) : '', "."] })), _jsx("p", __assign({ className: 'mainScreen__question' }, { children: t(quote) }))] }))] })));
};
export default MainScreen;
