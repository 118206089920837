import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { meInfoState } from './../../state';
import { useTranslation } from 'react-i18next';
import logoGoogle from '../../images/google.svg';
import logoFacebook from '../../images/fb.svg';
import './LoginButton.scss';
var BACKEND_API_URL = process.env.BACKEND_API_URL;
export var LoginButton = function (props) {
    // try {
    var me = useRecoilValue(meInfoState) || null;
    var param = props.param;
    var t = useTranslation().t;
    var logoImg = logoGoogle;
    var linkSrc = "".concat(BACKEND_API_URL, "/oauth2/authorization/google");
    switch (param) {
        case 'Facebook': {
            logoImg = logoFacebook;
            linkSrc = '/';
        }
    }
    useEffect(function () {
        var key = localStorage.getItem('inviteKey');
        if (!key && window.location.href.includes('inviteLink=')) {
            localStorage.setItem('inviteKey', window.location.href.split('inviteLink=')[1]);
        }
        if (key && me && me.sub) {
            window.location.href = "/groups/inviteLink=".concat(key);
        }
        else if (me && me.sub) {
            window.location.href = '/main';
        }
    }, [me]);
    var handleClick = function () {
        window.location.href = linkSrc;
    };
    return (_jsxs("button", __assign({ onClick: handleClick, className: "loginButton loginButton--".concat(param) }, { children: [_jsx("img", { src: logoImg, className: "loginButton__img" }), _jsxs("p", __assign({ className: 'loginButton__text' }, { children: [t('Continue with'), " ", param] }))] })));
    // } catch {
    //   const { t } = useTranslation()
    //   const handleClick = () => {
    //     window.location.href =
    //       `${BACKEND_API_URL}/oauth2/authorization/google`
    //   }
    //   return (
    //     <button onClick={handleClick} className='loginButton'>
    //       <div
    //         className='loginButton__img'
    //       ></div>
    //       {t('Login with Google')}
    //     </button>
    //   )
    // }
};
