import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
  en: {
    translation: {
      'Your votes': 'Your votes',
      'Your ideas': 'Your ideas',
      'Log Out': 'Log Out',
      'Login with Google': 'Login with Google',
      'Available groups': 'Available groups',
      'Invites to groups': 'Invites to groups',
      'My join requests to groups': 'My join requests to groups',
      'Leave group': 'Leave group',
      'Copy group ...': 'Copy group ...',
      'Left the group': 'You have successfully left the group',
      'Cant leave the group': "Can't leave the group",
      'You need to upload a picture': 'You need to upload a picture',
      'The picture is too big': 'The picture is too big, max size is 100kb',
      'The group must have a name': 'The group must have a name',
      'The group must have a description': 'The group must have a description',
      'Group successfully created': 'Group successfully created',
      'Something went wrong': 'Something went wrong',
      'You need to log in again': 'You need to log in again',
      'CREATE GROUP': 'CREATE GROUP',
      'Create group': 'Create group',
      Name: 'Name',
      Description: 'Description',
      'Entry mode': 'Entry mode',
      'Public tooltip':
        'Public – Any user can join to a group without approving from group’s administrator.',
      'Closed tooltip':
        'Closed – Any user can send join request to a group. Group is visible in the list of available groups.',
      'Private tooltip':
        'Private – User can join to group only if accept an invite from a group’s administrator. Group is not visible in available groups.',
      'Domain Restriction': 'Domain Restriction',
      'Join request question': 'Join request question',
      'Any information':
        'Any information about the user, which you want to know before accepting the user’s join request.',
      Cancel: 'Cancel',
      Next: 'Next',
      'Assigned to me': 'Assigned to me',
      'Assigned to': 'Assigned to',
      'Offered by me': 'Offered by me',
      'I voted': 'I voted',
      'Already done': 'Already done',
      Archived: 'Archived',
      'Filter ideas': 'Filter ideas',
      'newest first': 'newest first',
      'most voted first': 'most voted first',
      'most commented first': 'most commented first',
      'older first': 'older first',
      'offered by': 'offered by',
      'ASSIGN TO ME': 'ASSIGN TO ME',
      'Assigned to you': 'Assigned to you',
      EDIT: 'EDIT',
      'An idea must have a name': 'An idea must have a name',
      'An idea must have a description': 'An idea must have a description',
      'Idea added successfully': 'Idea added successfully',
      'You need to log in again': 'You need to log in again',
      'Add idea': 'Add idea',
      Summary: 'Summary',
      URL: 'URL',
      'Invite members': 'Invite members',
      'The number of users added to the group':
        'The number of users added to the group according to the tariff plan',
      'User or group': 'User or group',
      ADD: 'ADD',
      Create: 'Create',
      'GROUP PROPERTIES': 'GROUP PROPERTIES',
      'Group settings': 'Group settings',
      DELETE: 'DELETE',
      'The group must have an entry mode': 'The group must have an entry mode',
      'Join public groups and share ideas with their members':
        'Join public groups and share ideas with their members',
      'GROUP INFO': 'GROUP INFO',
      DENY: 'DENY',
      ACCEPT: 'ACCEPT',
      'Accept the invitation form':
        'Accept the invitation from the group administrator and share your ideas with the group members',
      Instantly: 'Instantly',
      Daily: 'Daily',
      Weekly: 'Weekly',
      'Account Settings': 'Account Settings',
      Profile: 'Profile',
      'Subscription plan': 'Subscription plan',
      '10 members in private groups': '10 members in private groups',
      "You can't set domain restriction": "You can't set domain restriction",
      CHANGE: 'CHANGE',
      Notifications: 'Notifications',
      'Notifications about new ideas':
        'Notifications about new ideas in your groups and replies to your comments',
      'Platform news': 'Platform news',
      'I want to receive news about platform updates':
        'I want to receive news about platform updates',
      UPDATE: 'UPDATE',
      CANCEL: 'CANCEL',
      Members: 'Members',
      'INVITE USERS TO GROUP': 'INVITE USERS TO GROUP',
      'MANAGE JOIN REQUESTS': 'MANAGE JOIN REQUESTS',
      'Idea edited successfully': 'Idea edited successfully',
      'Idea added successfully': 'Idea added successfully',
      'SAVE IDEA': 'SAVE IDEA',
      'ADD IDEA': 'ADD IDEA',
      'Something went wrong': 'Something went wrong',
      'Search group': 'Search group',
      'Search member': 'Search member',
      UPDATE: 'UPDATE',
      'Administrator of the group wants':
        'Administrator of the group wants to know some information about you. Please answer the question',
      'Thanks for readiness to pay!':
        'Thanks for readiness to pay! For end of 2022 year you can user service for free',
      'This site is protected': 'This site is protected by reCAPTCHA and the Google',
      'The best place': 'The best place to share ideas with friends'
    }
  },
  ru: {
    translation: {
      'Your votes': 'Ваши голоса',
      'Your ideas': 'Ваши идеи',
      'Log Out': 'Выйти',
      'Login with Google': 'Войти с помощью Google',
      'Available groups': 'Доступные группы',
      'Invites to groups': 'Приглашения в группу',
      'My join requests to groups': 'Мои запросы на вступление в группы',
      'Leave group': 'Покинуть группу',
      'Copy group ...': 'Копировать группу ...',
      'Left the group': 'Вы успешно покинули группу',
      'Cant leave the group': 'Не удается покинуть группу',
      'You need to upload a picture': 'Нужно загрузить картинку',
      'The picture is too big':
        'Картинка слишком большая, максимальный размер - 100кб',
      'The group must have a name': 'У группы должно быть имя',
      'The group must have a description': 'У группы должно быть описание',
      'Group successfully created': 'Группа успешно создана',
      'Something went wrong': 'Что-то пошло не так',
      'You need to log in again': 'Вам нужно снова авторизоваться',
      'CREATE GROUP': 'СОЗДАТЬ ГРУППУ',
      'Create group': 'Создать группу',
      Name: 'Имя',
      Description: 'Описание',
      'Entry mode': 'Режим входа',
      'Public tooltip':
        'Public – Любой пользователь может присоединиться к группе без разрешения администратора группы.',
      'Closed tooltip':
        'Closed – Любой пользователь может отправить запрос на присоединение к группе. Группа отображается в списке доступных групп.',
      'Private tooltip':
        'Private - Пользователь может присоединиться к группе, только если примет приглашение от администратора группы. Группа не отображается в доступных группах.',
      'Domain Restriction': 'Ограничение домена',
      'Join request question': 'Вопрос для присоединения',
      'Any information':
        'Любая информация о пользователе, которую вы хотите узнать перед принятием запроса на присоединение',
      Cancel: 'Отменить',
      Next: 'Продолжить',
      'Assigned to me': 'Назначено мне',
      'Assigned to': 'Назначено на',
      'Offered by me': 'Предложено мной',
      'I voted': 'Я проголосовал',
      'Already done': 'Уже сделано',
      Archived: 'В архиве',
      'Filter ideas': 'Фильтр идей',
      'Filter groups': 'Фильтр групп',
      'newest first': 'Сначала новые',
      'most voted first': 'По голосам',
      'most commented first': 'По комментариям',
      'older first': 'Сначала старые',
      'offered by': 'предложено',
      'ASSIGN TO ME': 'НАЗНАЧИТЬ МНЕ',
      'Assigned to you': 'Назначено вам',
      EDIT: 'РЕДАКТИРОВАТЬ',
      'Edit': 'Редактировать',
      'An idea must have a name': 'У идеи должно быть название',
      'An idea must have a description': 'У идеи должно быть описание',
      'Idea added successfully': 'Идея успешно добавлена',
      'You need to log in again': 'Вам нужно снова авторизоваться',
      'Add idea': 'Добавить идею',
      Summary: 'Тема',
      URL: 'Ссылка',
      'Invite members': 'Пригласить участников',
      'The number of users added to the group':
        'Добавляемое число пользователей в группу согласно тарифному плану',
      'User or group': 'Пользователь или группа',
      ADD: 'ДОБАВИТЬ',
      Create: 'Создать',
      'GROUP PROPERTIES': 'ПАРАМЕТРЫ ГРУППЫ',
      'Group settings': 'Настройки группы',
      DELETE: 'УДАЛИТЬ',
      'The group must have an entry mode': 'Выберите режим входа',
      'Join public groups and share ideas with their members':
        'Присоединяйтесь к доступным группам и делитесь идеями с их участниками',
      'GROUP INFO': 'ИНФО',
      DENY: 'ОТКЛОНИТЬ',
      ACCEPT: 'ПРИНЯТЬ',
      'Accept the invitation form':
        'Примите приглашение от администратора группы и поделитесь своими идеями с участниками группы',
      Instantly: 'Немедленно',
      Daily: 'Ежедневно',
      Weekly: 'Еженедельно',
      'Account Settings': 'Настройки аккаунта',
      Profile: 'Профиль',
      'Subscription plan': 'План подписки',
      '10 members in private groups': '10 участников в закрытых группах',
      "You can't set domain restriction":
        'Вы не можете установить ограничение домена',
      CHANGE: 'ИЗМЕНИТЬ',
      Notifications: 'Уведомления',
      'Notifications about new ideas':
        'Уведомления о новых идеях в ваших группах и ответы на ваши комментарии',
      'Platform news': 'Новости платформы',
      'I want to receive news about platform updates':
        'Я хочу получать новости об обновлениях платформы',
      UPDATE: 'ОБНОВИТЬ',
      CANCEL: 'ОТМЕНИТЬ',
      Members: 'Участники',
      members: 'участников',
      'INVITE USERS TO GROUP': 'ПРИГЛАСИТЬ В ГРУППУ',
      'MANAGE JOIN REQUESTS': 'УПРАВЛЕНИЕ ЗАПРОСАМИ',
      'Idea edited successfully': 'Идея успешно отредактирована',
      'Idea added successfully': 'Идея успешно добавлена',
      'SAVE IDEA': 'СОХРАНИТЬ',
      'ADD IDEA': 'ДОБАВИТЬ',
      'Something went wrong': 'Что-то пошло не так :(',
      'Search group': 'Найти группу',
      'Search member': 'Найти участника',
      UPDATE: 'ОБНОВИТЬ',
      'This action delete your group with all ideas and comments.':
        'Удаление группы приведет к удалению всех ее идей и комментариев',
      'Group successfully changed': 'Группа успешно изменена',
      "or you're haven't permissions": 'или у вас недостаточно прав',
      "You're haven't permissions": 'У вас недостаточно прав',
      ideas: 'идей',
      'You sent request': 'Вы отправили запрос',
      'invited you': 'пригласил вас',
      REVOKE: 'ОТКЛОНИТЬ',
      'ACCEPT INVITE': 'ПРИНЯТЬ ПРИГЛАШЕНИЕ',
      'JOIN TO GROUP': 'ПРИСОЕДИНИТЬСЯ',
      'REQUEST ACCESS': 'ОТПРАВИТЬ ЗАПРОС',
      'YOU REQUEST IS STILL NOT RESOLVED': 'ВАШ ЗАПРОС ЕЩЕ НЕ ПРИНЯТ',
      'Group`s request message': 'Сообщение с запросом группы',
      REQUEST: 'ОТПРАВИТЬ ЗАПРОС',
      Answer: 'Ответ',
      Message: 'Сообщение',
      'Administrator of the group wants':
        'Администратор группы хочет узнать о вас некоторую информацию. Пожалуйста ответьте на вопрос',
      "If you want you can write a message for group's administrator":
        'Если хотите, можете написать сообщение администратору группы',
      Remove: 'Удалить',
      'Member deleted succesfully': 'Участник успешно удален',
      'Member cannot be removed': 'Участник не может быть удален',
      'group admin': 'Администратор',
      'Search groups': 'Найти группы',
      'Thanks for readiness to pay!':
        'Спасибо за готовность платить! До конца 2022 года вы можете пользоваться сервисом бесплатно',
      'UN VOTE': 'ОТМЕНИТЬ ГОЛОС',
      VOTE: 'ПРОГОЛОСОВАТЬ',
      'already has votes': 'Проголосовало',
      'Be the first who': 'Будь первым кто ',
      'Link copied to clipboard': 'Ссылка успешно скопирована',
      CLOSE: 'ЗАКРЫТЬ',
      'implemented by': 'реализовано',
      'archived by': 'архивировано',
      'Tariff plan successfully updated': 'Тарифный план успешно обновлен',
      'Account settings successfully updated':
        'Настройки аккаунта успешно обновлены',
      'Invite is accepting succesfully': 'Приглашение успешно принято',
      'Join request is sending succesfully':
        'Запрос на присоединение успешно отправлен',
      'Join request is accepted succesfully':
        'Запрос на присоединение успешно одобрен',
      'Only public': 'Только общедоступные',
      'With my join requests': 'С моими запросами',
      'Which are invited me': 'С приглашением',
      'The link is not valid': 'Некорректная ссылка',
      Optional: 'Необязательно',
      'Looks like link not found': 'Похоже, ссылка не найдена',
      'Go to main page': 'Перейти на главную',
      'Delete group?': 'Удалить группу?',
      'Invite link': 'Ссылка для приглашения',
      'You can restrict access':
        'Вы можете ограничить доступ к группе по домену электронной почты пользователя.',
      'Link updated successfully': 'Ссылка успешно обновлена',
      'Link copied successfully': 'Ссылка успешно скопирована',
      'Select ordering': 'Выберите сортировку',
      'Newest first': 'Сначала новые',
      'Older first': 'Сначала старые',
      'Title A-Z': 'А-Я',
      'Title Z-A': 'Я-А',
      'Add a comment...': 'Добавить комментарий',
      'Idea is archived': 'Идея архивирована',
      'Idea is implemented': 'Идея выполнена',
      'Assignee changed successfully': 'Назначенный успешно изменен',
      'Assignee cannot be changed': 'Назначенного нельзя изменить',
      'Idea moved successfully': 'Идея успешно перемещена',
      'Change Assignee': 'Сменить назначенного',
      'Move to group': 'Переместить в группу',
      'now assigned to': 'сейчас назначено на',
      'Idea is not assigned to anybody': 'Идея не назначена',
      'MOVE': 'Переместить',
      'Assignee removed successfully': 'Назначенный успешно изменен',
      'Operation is not permitted': 'Операция недоступна',
      'Idea deleted successfully': 'Идея успешно удалена',
      'Idea archived successfully': 'Идея архивирована',
      'Idea is marked as not completed successfully': 'Идея помечена как невыполненная',
      'Idea marked as undone successfully': 'Идея разархивирована',
      'Idea marked as done successfully': 'Идея помечена как выполненная',
      'Assignee successfully changed': 'Назначенный успешно изменен',
      'Members invited successfully': 'Новые участники успешно приглашены',
      'Invite message': 'Сообщение',
      'Account': 'Аккаунт',
      'Logout': 'Выйти',
      'Sorry, but page not found': 'Страница не найдена',
      'Unarchived': 'Разархивировать',
      'Mark as undone': 'Пометить невыполненной',
      'Remove assignee': 'Удалить назначенного',
      'Mark as Done': 'Пометить выполненной',
      'Change assignee': 'Сменить назначенного',
      'Archive': 'Архивировать',
      'Move to group...': 'Переместить в группу...',
      'Delete': 'Удалить',
      'Something went wrong! Please try login again!': 'Что-то пошло не так! Выполните вход снова, пожалуйста!',
      'Continue with': 'Продолжить с',
      'This site is protected': 'Этот сайт защищен reCAPTCHA и Google',
      'The best place': 'Лучшее место для обмена идеями с друзьями',
      'apply': 'применяются',
      'Privacy Policy': 'Политика конфиденциальности',
      'Terms of Service': 'Условия предоставления услуг',
      'and': 'и',
      'Welcome to': 'Добро пожаловать в',
      'Comment deleted succesfully': 'Комментарий успешно удален',
      'Comment cannot be removed': 'Комментарий не может быть удален',
      'REPLY': 'Ответить',
      'Read more': 'Показать еще',
    }
  }
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })

export default i18n
