export var defaultGroups = {
    id: '',
    ctime: '',
    creator: {
        id: '',
        email: '',
        displayName: '',
        avatar: ''
    },
    state: '',
    name: '',
    description: '',
    logo: '',
    entryMode: '',
    entryQuestion: '',
    domainRestrictions: [''],
    membersCount: 0,
    ideasCount: 0,
    joiningKey: '',
    deleted: false
};
