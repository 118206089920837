import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
export var ShowAlert = function (props) {
    var open = props.open, severity = props.severity, message = props.message;
    var _a = useState(open), openSnackbar = _a[0], setOpenSnackbar = _a[1];
    var t = useTranslation().t;
    var handleCloseSnackbar = function () {
        setTimeout(function () { return setOpenSnackbar(false); }, 1000);
    };
    return (_jsx(Snackbar, __assign({ open: openSnackbar, autoHideDuration: 3000, onClose: handleCloseSnackbar }, { children: _jsx(Alert, __assign({ onClose: handleCloseSnackbar, severity: severity }, { children: t(message) })) })));
};
export var Alert = function (props) {
    return _jsx(MuiAlert, __assign({ elevation: 6, variant: 'filled' }, props));
};
