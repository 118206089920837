import { __awaiter, __generator } from "tslib";
var BACKEND_API_URL = process.env.BACKEND_API_URL;
var fetchRequest = function (token, url, method, body, content) {
    if (method === void 0) { method = 'GET'; }
    if (content === void 0) { content = null; }
    return __awaiter(void 0, void 0, void 0, function () {
        var response, responseData, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, fetch(url, {
                            method: method,
                            credentials: 'include',
                            headers: {
                                Authorization: "Bearer ".concat(token),
                                'Content-Type': content || 'text/plain'
                            },
                            body: body
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    responseData = _a.sent();
                    if (responseData.data) {
                        return [2 /*return*/, responseData.data];
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    });
};
export var fetchAuthor = function (authorId, token) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/users/").concat(authorId);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!((_a.sent()) && authorId)) return [3 /*break*/, 4];
                return [4 /*yield*/, request.displayName];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var fetchMe = function (token, userId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/users/").concat(userId);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!((_a.sent()) && userId)) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var fetchGroup = function (token, groupId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, []];
        }
    });
}); };
export var fetchGroupByKey = function (token, key) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups?key=").concat(key);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, []];
        }
    });
}); };
export var updateToken = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/token");
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, ''];
        }
    });
}); };
export var fetchRequests = function (token, userId, urlParams, skip, count) {
    if (urlParams === void 0) { urlParams = null; }
    if (skip === void 0) { skip = 0; }
    if (count === void 0) { count = 10; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(BACKEND_API_URL, "/joinrequests?userId=").concat(userId, "&").concat(urlParams ? urlParams : '', "skip=").concat(skip, "&count=").concat(count);
                    return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
                case 1:
                    request = _a.sent();
                    return [4 /*yield*/, request];
                case 2:
                    if (!_a.sent()) return [3 /*break*/, 4];
                    return [4 /*yield*/, request];
                case 3: return [2 /*return*/, _a.sent()];
                case 4: return [2 /*return*/, 'undefined'];
            }
        });
    });
};
export var fetchAllGroups = function (token, userId, skip, count) {
    if (skip === void 0) { skip = 0; }
    if (count === void 0) { count = 10; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(BACKEND_API_URL, "/groups?userId=").concat(userId, "&skip=").concat(skip, "&count=").concat(count);
                    return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
                case 1:
                    request = _a.sent();
                    return [4 /*yield*/, request];
                case 2:
                    if (!_a.sent()) return [3 /*break*/, 4];
                    return [4 /*yield*/, request];
                case 3: return [2 /*return*/, _a.sent()];
                case 4: return [2 /*return*/, 'undefined'];
            }
        });
    });
};
export var fetchGroups = function (token, userId, name) {
    if (name === void 0) { name = ''; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = name
                        ? "".concat(BACKEND_API_URL, "/groups?userId=").concat(userId, "&name=").concat(name)
                        : "".concat(BACKEND_API_URL, "/groups?userId=").concat(userId);
                    return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
                case 1:
                    request = _a.sent();
                    return [4 /*yield*/, request];
                case 2:
                    if (!_a.sent()) return [3 /*break*/, 4];
                    return [4 /*yield*/, request.groups];
                case 3: return [2 /*return*/, _a.sent()];
                case 4: return [2 /*return*/, 'undefined'];
            }
        });
    });
};
export var searchMembersByName = function (letters, token) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/users?filter=").concat(letters);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var searchMembersByNameFromGroup = function (token, userId, groupName) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups?userId=").concat(userId, "&name=").concat(groupName);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var deleteIdea = function (token, ideaId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId);
                return [4 /*yield*/, fetchRequest(token, url, 'DELETE', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request.idea];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var removeMember = function (token, groupId, memberId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId, "/members/").concat(memberId);
                return [4 /*yield*/, fetchRequest(token, url, 'DELETE', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var resoluteInvite = function (inviteId, token, status) { return __awaiter(void 0, void 0, void 0, function () {
    var url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/invites/").concat(inviteId, "/status");
                body = JSON.stringify({ status: status });
                return [4 /*yield*/, fetchRequest(token, url, 'PATCH', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var resoluteJoinRequest = function (joinRequestId, token, status) { return __awaiter(void 0, void 0, void 0, function () {
    var url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/joinrequests/").concat(joinRequestId, "/status");
                body = JSON.stringify({ status: status });
                return [4 /*yield*/, fetchRequest(token, url, 'PATCH', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var patchAssignee = function (token, ideaId, body) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/assignee");
                return [4 /*yield*/, fetchRequest(token, url, 'PATCH', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request.idea];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var patchMoveToGroup = function (token, ideaId, body) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/group");
                return [4 /*yield*/, fetchRequest(token, url, 'PATCH', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request.idea];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var patchIdeaArchive = function (token, ideaId, archived) { return __awaiter(void 0, void 0, void 0, function () {
    var body, url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = JSON.stringify({
                    archived: archived
                });
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/archived");
                return [4 /*yield*/, fetchRequest(token, url, 'PATCH', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request.idea];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var patchIdeaImplemented = function (token, ideaId, implemented) { return __awaiter(void 0, void 0, void 0, function () {
    var body, url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = JSON.stringify({
                    implemented: implemented
                });
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/implemented");
                return [4 /*yield*/, fetchRequest(token, url, 'PATCH', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request.idea];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var postVote = function (token, ideaId, method) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/voters");
                return [4 /*yield*/, fetchRequest(token, url, method, null, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request.idea];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var postInvites = function (token, groupId, members, message) { return __awaiter(void 0, void 0, void 0, function () {
    var users, url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                users = [];
                if (members.length) {
                    members.forEach(function (member) { return users.push(member.id); });
                }
                url = "".concat(BACKEND_API_URL, "/invites/");
                body = JSON.stringify({
                    groupId: groupId,
                    message: message,
                    registeredUsersIds: users,
                    newUsersEmails: []
                });
                return [4 /*yield*/, fetchRequest(token, url, 'POST', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var putSettings = function (token, settings, planSettings, tariff) { return __awaiter(void 0, void 0, void 0, function () {
    var url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/users/settings");
                body = JSON.stringify({
                    displayName: planSettings.name,
                    subscriptionPlan: tariff || planSettings.subscriptionPlan,
                    settings: {
                        notificationsFrequency: settings.notifications.toUpperCase(),
                        subscribedToNews: settings.checked
                    }
                });
                return [4 /*yield*/, fetchRequest(token, url, 'PUT', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var postNewIdea = function (token, method, url, ideaParams) { return __awaiter(void 0, void 0, void 0, function () {
    var body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                body = JSON.stringify(ideaParams);
                return [4 /*yield*/, fetchRequest(token, url, method, body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var postNewGroup = function (token, groupParams) { return __awaiter(void 0, void 0, void 0, function () {
    var url, params, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups");
                params = Object.assign({}, groupParams);
                if (params.domainRestrictions.length) {
                    params.domainRestrictions = groupParams.domainRestrictions.split(',');
                }
                else {
                    params.domainRestrictions = [];
                }
                body = JSON.stringify(params);
                return [4 /*yield*/, fetchRequest(token, url, 'POST', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var patchGroup = function (token, groupParams, groupId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId);
                if (groupParams.domainRestrictions.length) {
                    groupParams.domainRestrictions = groupParams.domainRestrictions.split(',');
                }
                else {
                    groupParams.domainRestrictions = [];
                }
                body = JSON.stringify(groupParams);
                return [4 /*yield*/, fetchRequest(token, url, 'PATCH', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var postJoinRequest = function (token, joiningKey, message) { return __awaiter(void 0, void 0, void 0, function () {
    var url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/joinrequests/");
                body = JSON.stringify({
                    joiningKey: joiningKey,
                    message: message
                });
                return [4 /*yield*/, fetchRequest(token, url, 'POST', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var fetchMembers = function (token, groupId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId, "/members");
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var fetchGroupRequests = function (token, groupId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/joinrequests?groupId=").concat(groupId);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var fetchIdeas = function (token, groupId, urlParams, skip, count) {
    if (urlParams === void 0) { urlParams = null; }
    if (skip === void 0) { skip = 0; }
    if (count === void 0) { count = 10; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(BACKEND_API_URL, "/ideas?groupId=").concat(groupId, "&skip=").concat(skip, "&count=").concat(count, "&").concat(urlParams);
                    return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
                case 1:
                    request = _a.sent();
                    return [4 /*yield*/, request];
                case 2:
                    if (_a.sent()) {
                        return [2 /*return*/, request];
                    }
                    else {
                        return [2 /*return*/, 'undefined'];
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var fetchIdeaById = function (token, ideaId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (_a.sent()) {
                    return [2 /*return*/, request];
                }
                else {
                    return [2 /*return*/, 'undefined'];
                }
                return [2 /*return*/];
        }
    });
}); };
export var fetchInvites = function (token, userId, urlParams, skip, count) {
    if (urlParams === void 0) { urlParams = null; }
    if (skip === void 0) { skip = 0; }
    if (count === void 0) { count = 10; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(BACKEND_API_URL, "/invites?userId=").concat(userId, "&").concat(urlParams ? urlParams : '', "skip=").concat(skip, "&count=").concat(count);
                    return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
                case 1:
                    request = _a.sent();
                    return [4 /*yield*/, request];
                case 2:
                    if (_a.sent()) {
                        return [2 /*return*/, request];
                    }
                    else {
                        return [2 /*return*/, 'undefined'];
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var fetchAvailibaleGroups = function (token, urlParams, skip, count) {
    if (urlParams === void 0) { urlParams = null; }
    if (skip === void 0) { skip = 0; }
    if (count === void 0) { count = 10; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(BACKEND_API_URL, "/groups?onlyAvailable=true&").concat(urlParams ? urlParams : '', "skip=").concat(skip, "&count=").concat(count);
                    return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
                case 1:
                    request = _a.sent();
                    return [4 /*yield*/, request];
                case 2:
                    if (!_a.sent()) return [3 /*break*/, 4];
                    return [4 /*yield*/, request];
                case 3: return [2 /*return*/, _a.sent()];
                case 4: return [2 /*return*/];
            }
        });
    });
};
export var fetchMembersSearch = function (token, groupId, search) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId, "/members?username=").concat(search);
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var revokeRequest = function (token, requestId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/joinrequests/").concat(requestId);
                return [4 /*yield*/, fetchRequest(token, url, 'DELETE', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var updateJoiningKey = function (token, groupId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId, "/joining-key");
                return [4 /*yield*/, fetchRequest(token, url, 'DELETE', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var leaveGroup = function (token, groupId, userId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId, "/members/").concat(userId);
                return [4 /*yield*/, fetchRequest(token, url, 'DELETE', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var deleteGroup = function (token, groupId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/groups/").concat(groupId);
                return [4 /*yield*/, fetchRequest(token, url, 'DELETE', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var fetchAccoutSettings = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/users/settings");
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var tryGetToken = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/token");
                return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var postNewComment = function (token, ideaId, commentContent) { return __awaiter(void 0, void 0, void 0, function () {
    var url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/comments");
                body = JSON.stringify(commentContent);
                return [4 /*yield*/, fetchRequest(token, url, 'POST', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
export var fetchComments = function (token, ideaId, skip, count) {
    if (skip === void 0) { skip = 0; }
    if (count === void 0) { count = 10; }
    return __awaiter(void 0, void 0, void 0, function () {
        var url, request;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/comments?skip=").concat(skip, "&count=").concat(count);
                    return [4 /*yield*/, fetchRequest(token, url, 'GET', null)];
                case 1:
                    request = _a.sent();
                    return [4 /*yield*/, request];
                case 2:
                    if (_a.sent()) {
                        return [2 /*return*/, request];
                    }
                    else {
                        return [2 /*return*/, 'undefined'];
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var deleteComment = function (token, ideaId, commentId) { return __awaiter(void 0, void 0, void 0, function () {
    var url, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/comments/").concat(commentId);
                return [4 /*yield*/, fetchRequest(token, url, 'DELETE', null)];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/, 'undefined'];
        }
    });
}); };
export var editComment = function (token, ideaId, commentId, commentContent) { return __awaiter(void 0, void 0, void 0, function () {
    var url, body, request;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                url = "".concat(BACKEND_API_URL, "/ideas/").concat(ideaId, "/comments/").concat(commentId);
                body = JSON.stringify(commentContent);
                return [4 /*yield*/, fetchRequest(token, url, 'PUT', body, 'application/json')];
            case 1:
                request = _a.sent();
                return [4 /*yield*/, request];
            case 2:
                if (!_a.sent()) return [3 /*break*/, 4];
                return [4 /*yield*/, request];
            case 3: return [2 /*return*/, _a.sent()];
            case 4: return [2 /*return*/];
        }
    });
}); };
