import { __awaiter, __generator } from "tslib";
import { atom, selector } from 'recoil';
import { defaultGroups } from './types/Groups';
import { fetchIdeas, fetchMembers, fetchInvites, fetchIdeaById, fetchGroupByKey, fetchGroup } from './functionsRequests';
import jwt_decode from "jwt-decode";
var BACKEND_API_URL = process.env.BACKEND_API_URL;
export var token = selector({
    key: 'token',
    get: function (_a) {
        var get = _a.get;
        return get(tokenState);
    },
    set: function (_a, newValue) {
        var set = _a.set, get = _a.get;
        set(tokenState, newValue);
        get(meInfoState);
    }
});
export var tokenState = atom({
    key: 'tokenState',
    default: localStorage.getItem('jwt') || ''
});
export var maxMembersState = atom({
    key: 'maxMembersState',
    default: 10
});
export var groupNameState = atom({
    key: 'groupNameState',
    default: ''
});
export var currentGroupIdFromLocationState = atom({
    key: 'currentGroupIdFromLocationState',
    default: window.location.href.split('/')[3] == 'group'
        ? window.location.href.split('/')[4]
        : ''
});
export var meInfoState = selector({
    key: 'meInfoState',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var token_1, decodedToken;
            return __generator(this, function (_b) {
                try {
                    token_1 = get(tokenState) || localStorage.getItem('jwt');
                    if (typeof token_1 == 'string') {
                        decodedToken = jwt_decode(token_1);
                        return [2 /*return*/, decodedToken];
                    }
                    return [2 /*return*/, null];
                }
                catch (error) {
                    throw new Error("Error in 'allUserGroups': ".concat(error.message));
                }
                return [2 /*return*/];
            });
        });
    },
    set: function (_a, newValue) {
        var set = _a.set;
        set(infoMeAtomState, newValue);
    }
});
export var infoMeAtomState = atom({
    key: 'infoMeAtomState',
    default: meInfoState
});
export var allUserGroups = selector({
    key: 'allUserGroups',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var userId, token_2, response, responseData, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        if (!get(infoMeAtomState)) return [3 /*break*/, 3];
                        userId = get(infoMeAtomState).sub;
                        if (!userId) return [3 /*break*/, 3];
                        token_2 = get(tokenState);
                        return [4 /*yield*/, fetch("".concat(BACKEND_API_URL, "/groups?userId=").concat(userId, " "), {
                                credentials: 'include',
                                headers: {
                                    'Access-Control-Allow-Origin': 'true',
                                    Authorization: "Bearer ".concat(token_2)
                                }
                            })];
                    case 1:
                        response = _b.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        responseData = _b.sent();
                        return [2 /*return*/, responseData.data.groups || []];
                    case 3: return [2 /*return*/, []];
                    case 4:
                        error_1 = _b.sent();
                        throw new Error("Error in 'allUserGroups': ".concat(error_1.message));
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    set: function (_a, newValue) {
        var set = _a.set;
        set(allUserGroupsState, newValue);
    }
});
export var allUserGroupsState = atom({
    key: 'allUserGroupsState',
    default: allUserGroups
});
export var invitesSelector = selector({
    key: 'invitesSelector',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var userId, token_3, error_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, , 5]);
                        if (!get(infoMeAtomState)) return [3 /*break*/, 3];
                        userId = get(infoMeAtomState).sub;
                        if (!userId) return [3 /*break*/, 2];
                        token_3 = get(tokenState);
                        return [4 /*yield*/, fetchInvites(token_3, userId, null)];
                    case 1: return [2 /*return*/, _b.sent()];
                    case 2: return [2 /*return*/, []];
                    case 3: return [2 /*return*/, false];
                    case 4:
                        error_2 = _b.sent();
                        throw new Error("Error in 'invitesSelector': ".concat(error_2.message));
                    case 5: return [2 /*return*/];
                }
            });
        });
    },
    set: function (_a, newValue) {
        var set = _a.set;
        set(invitesState, newValue);
    }
});
export var invitesState = atom({
    key: 'invitesState',
    default: invitesSelector
});
export var groupIdState = atom({
    key: 'groupIdState',
    default: localStorage.getItem('currentGroupId') || ''
});
export var joinRequestsSelector = selector({
    key: 'joinRequestsSelector',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var userId;
            return __generator(this, function (_b) {
                try {
                    if (get(infoMeAtomState)) {
                        userId = get(infoMeAtomState).sub;
                        if (userId) {
                            return [2 /*return*/, { groups: [], joinRequests: [], invites: [] }];
                        }
                        return [2 /*return*/, { groups: [], joinRequests: [], invites: [] }];
                    }
                    return [2 /*return*/, false];
                }
                catch (error) {
                    throw new Error("Error in 'joinRequestsSelector': ".concat(error.message));
                }
                return [2 /*return*/];
            });
        });
    },
    set: function (_a, newValue) {
        var set = _a.set;
        set(joinRequestsState, newValue);
    }
});
export var joinRequestsState = atom({
    key: 'joinRequestsState',
    default: joinRequestsSelector
});
export var currentGroupSelector = selector({
    key: 'currentGroupSelector',
    get: function (_a) {
        var get = _a.get;
        var token = get(tokenState);
        try {
            if (token && window.location.href.split('/')[3] == 'group') {
                return fetchGroup(token, window.location.href.split('/')[4]).then(function (res) {
                    if (res !== 'undefined' && res.id) {
                        return res;
                    }
                });
            }
            return defaultGroups;
        }
        catch (error) {
            throw new Error("Error in 'currentGroupSelector': ".concat(error.message));
        }
        // return get(currentGroupState)
    },
    set: function (_a, newValue) {
        var set = _a.set, get = _a.get;
        set(currentGroupState, newValue);
    }
});
export var currentGroupState = atom({
    key: 'currentGroupState',
    default: currentGroupSelector || defaultGroups
});
export var ideasSelector = selector({
    key: 'ideasSelector',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var groupId, ideaId, token_4, idea, token_5, error_3;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 8, , 9]);
                        groupId = '';
                        if (!(window.location.href.split('/')[3] == 'ideas')) return [3 /*break*/, 5];
                        ideaId = window.location.href.split('/')[4];
                        if (!ideaId) return [3 /*break*/, 4];
                        token_4 = get(tokenState);
                        return [4 /*yield*/, fetchIdeaById(token_4, ideaId)];
                    case 1:
                        idea = _b.sent();
                        return [4 /*yield*/, idea];
                    case 2:
                        if (!((_b.sent()) !== 'undefined')) return [3 /*break*/, 4];
                        groupId = idea.idea.groupId;
                        localStorage.setItem('currentGroupId', groupId);
                        if (!groupId) return [3 /*break*/, 4];
                        return [4 /*yield*/, fetchIdeas(token_4, groupId)];
                    case 3: return [2 /*return*/, _b.sent()];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        if (!(get(currentGroupState) ||
                            get(currentGroupIdFromLocationState))) return [3 /*break*/, 7];
                        groupId =
                            get(currentGroupState).id || get(currentGroupIdFromLocationState);
                        if (!groupId) return [3 /*break*/, 7];
                        token_5 = get(tokenState);
                        return [4 /*yield*/, fetchIdeas(token_5, groupId)];
                    case 6: return [2 /*return*/, _b.sent()];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        error_3 = _b.sent();
                        throw new Error("Error in 'ideasSelector': ".concat(error_3.message));
                    case 9: return [2 /*return*/, { ideas: [], users: [] }];
                }
            });
        });
    },
    set: function (_a, newValue) {
        var set = _a.set;
        return set(ideasState, newValue);
    }
});
export var ideasState = atom({
    key: 'ideasState',
    default: ideasSelector
});
export var availablesGroupsSelector = selector({
    key: 'availablesGroupsSelector',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var token;
            return __generator(this, function (_b) {
                token = get(tokenState);
                if (token) {
                    try {
                        return [2 /*return*/, { groups: [], joinRequests: [], invites: [] }];
                    }
                    catch (error) {
                        throw new Error("Error in 'AvailablesGroupsState': ".concat(error.message));
                    }
                }
                return [2 /*return*/, { groups: [], joinRequests: [], invites: [] }];
            });
        });
    },
    set: function (_a, newValue) {
        var set = _a.set;
        set(availablesGroupsState, newValue);
    }
});
export var availablesGroupsState = atom({
    key: 'availablesGroupsState',
    default: availablesGroupsSelector
});
export var membersSelector = selector({
    key: 'membersSelector',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var token, groupId, error_4;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        get(currentGroupState);
                        get(currentGroupIdFromLocationState);
                        token = get(tokenState);
                        if (!(token && window.location.href.split('/')[3] == 'group')) return [3 /*break*/, 4];
                        groupId = window.location.href.split('/')[4];
                        if (!groupId) return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, fetchMembers(token, groupId)];
                    case 2: return [2 /*return*/, _b.sent()];
                    case 3:
                        error_4 = _b.sent();
                        throw new Error("Error in 'membersState': ".concat(error_4.message));
                    case 4: return [2 /*return*/, []];
                }
            });
        });
    },
    set: function (_a, newValue) {
        var get = _a.get;
        get(membersState);
    }
});
export var membersState = atom({
    key: 'membersState',
    default: membersSelector
});
export var groupByKeySelector = selector({
    key: 'groupByKeySelector',
    get: function (_a) {
        var get = _a.get;
        return __awaiter(void 0, void 0, void 0, function () {
            var token, key;
            return __generator(this, function (_b) {
                token = get(tokenState);
                if (token) {
                    key = window.location.href.split('inviteLink=')[1];
                    localStorage.setItem('inviteKey', key);
                    try {
                        return [2 /*return*/, fetchGroupByKey(token, key).then(function (res) {
                                if (res) {
                                    return Array(1).fill(res);
                                }
                            })];
                    }
                    catch (error) {
                        throw new Error("Error in 'groupByKeyState': ".concat(error.message));
                    }
                }
                return [2 /*return*/, []];
            });
        });
    },
    set: function (_a, newValue) {
        var set = _a.set;
        set(groupByKeyState, newValue);
    }
});
export var groupByKeyState = atom({
    key: 'groupByKeyState',
    default: groupByKeySelector
});
export var authorsNamesSelector = selector({
    key: 'authorsNamesSelector',
    get: function (_a) {
        var get = _a.get;
        if (get(authorsNamesState))
            return get(authorsNamesState);
    },
    set: function (_a, newValue) {
        var set = _a.set;
        return set(authorsNamesState, newValue);
    }
});
export var authorsNamesState = atom({
    key: 'authorsNamesState',
    default: []
});
export var mobileOpenSelector = selector({
    key: 'mobileOpenSelector',
    get: function (_a) {
        var get = _a.get;
        return get(mobileOpenState);
    },
    set: function (_a) {
        var set = _a.set, get = _a.get;
        return set(mobileOpenState, !get(mobileOpenState));
    }
});
export var mobileOpenState = atom({
    key: 'mobileOpenState',
    default: false
});
export var createGroupOpenSelector = selector({
    key: 'createGroupOpenSelector',
    get: function (_a) {
        var get = _a.get;
        return get(createGroupOpenState);
    },
    set: function (_a) {
        var set = _a.set, get = _a.get;
        return set(createGroupOpenState, !get(createGroupOpenState));
    }
});
export var createGroupOpenState = atom({
    key: 'createGroupOpenState',
    default: false
});
export var mobileOpenRightSelector = selector({
    key: 'mobileOpenRightSelector',
    get: function (_a) {
        var get = _a.get;
        return get(mobileOpenRightState);
    },
    set: function (_a) {
        var set = _a.set, get = _a.get;
        return set(mobileOpenRightState, !get(mobileOpenRightState));
    }
});
export var mobileOpenRightState = atom({
    key: 'mobileOpenRightState',
    default: false
});
export var groupFiltersSelector = selector({
    key: 'groupFiltersSelector',
    get: function (_a) {
        var get = _a.get;
        return get(groupFilterState);
    },
    set: function (_a, newValue) {
        var set = _a.set;
        set(groupFilterState, newValue);
        localStorage.setItem('groupFilterState', JSON.stringify(newValue));
    }
});
export var groupFilterState = atom({
    key: 'groupFilterState',
    default: JSON.parse(localStorage.getItem('groupFilterState')) || {
        id: 'states'
    }
});
