import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { RecoilRoot } from 'recoil';
import { useTranslation } from 'react-i18next';
import './index.scss';
import './i18n';
import { CircularProgress } from '@material-ui/core';
import { ErrorBoundary } from 'react-error-boundary';
import { ShowAlert } from './components/Alert/Alert';
import MainScreen from './components/MainScreen/MainScreen';
import './fonts/stylesheet.css';
function ErrorFallback(_a) {
    var error = _a.error, resetErrorBoundary = _a.resetErrorBoundary;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: 'app' }, { children: [_jsx(MainScreen, {}), _jsx(ShowAlert, { open: true, severity: 'error', message: t('Something went wrong! Please try login again!') })] })));
}
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(RecoilRoot, { children: _jsx(Suspense, __assign({ fallback: _jsx("div", __assign({ className: 'app__suspense' }, { children: _jsx(CircularProgress, {}) })) }, { children: _jsx(ErrorBoundary, __assign({ FallbackComponent: ErrorFallback, onReset: function () { } }, { children: _jsx(App, {}) })) })) }) }), document.getElementById('root'));
